.content {
  h3 {
    padding-top: 150px;
    margin-bottom: 10px;
  }
  p {
    margin-bottom: 0;
  }
  p, h3 {
    text-align: center;
  }
  .icon {
    position: absolute;
    right: calc(50% - 85.5px);
  }
  margin-bottom: 30px;
}

.footer {
  height: 45px;
  display: flex;
  button {
    flex: 1;
  }
}

.footerAlert {
  text-align: center;
  &__btn {
    color: #fea628;
    padding: 8px 10px;
    min-width: 64px;
    border-radius: 4px;
    border: none;
    outline: none;
    background-color: #fff;
    cursor: pointer;
    &:hover {
      background-color: rgba(25, 118, 210, 0.04);
    }
  }
}