.pageWrapper {
  height: 100vh;
  display: flex;
}
.mainWrapper {
  height: 100%;
  flex: 1;
}
.pageContent {
  flex: 1;
  background: #fff;
  padding-top: 60px;
  height: calc(100vh - 60px);
  :global {
    .ant-spin {
      position: absolute;
      top: calc(50% - 16px);
      left: calc(50% - 19px);
    }
  }
}
.spinContainer {
  width: 100vw;
  height: 100vh;
  :global {
    .ant-spin {
      position: absolute;
      top: calc(50vh - 16px);
      left: calc(50vw - 19px);
    }
  }
}