.contentTitle {
  font-size: 18px;
}
.title {
  font-weight: 500;
  color: #223354;
}
.contentItem {
  margin-right: 10px;
  padding: 5px 0 5px 10px;
  &:hover {
    background-color: #f3f3f4;
    border-radius: 5px;
    cursor: pointer;
  }
}

.badge {
  :global {
    .ant-badge-status-gold {
      width: 10px;
      height: 10px;
    }
  }
}

.name {
  font-size: 15px;
}

.message {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #464646;
  margin-bottom: 5px;
}

.createAt {
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #909090;
}