@import "~styles/variable";

.subHeaderWrapper {
  flex: 1;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.subHeaderWrapper {
  background: inherit;
  border-bottom: none;
  color: #3B3B3B;
  height: 100%;
  :global {
    .ant-menu-item::after {
      left: 0;
      right: 0;
    }
    .ant-menu-item-selected::after, .ant-menu-item:hover::after {
      border-bottom: 3px solid $main-color !important;
    }
    .ant-menu-item {
      width: 150px;
      margin-right: 10px;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .ant-menu-item:hover, .ant-menu-item-selected {
      color: #3B3B3B !important;
      a {
        font-weight: 700 !important;
        color: #3B3B3B !important;
      }
    }
    .ant-menu-title-content {
      a {
        color: #888888;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        &:hover {
          color: #3B3B3B;
        }
      }
    }
  }
}