@import "~styles/variable";

.notiContainer {
  :global {
    .MuiTooltip-popper {
      width: 745px;
      height: 730px;
      box-shadow: 0px 5px 25px rgba(34, 51, 84, 0.15);
      border: 1px solid #FFFFFF;
      background: #F9FBFC;
      transform: none !important;
      inset: 50px 0 0 auto !important;
    }
    .MuiTooltip-tooltip {
      background: $white-color;
      min-width: 100%;
      height: 100%;
      cursor: default;
      margin: 0 !important;
      padding: 0 !important;
    }
    .MuiTooltip-arrow {
      color: $white-color;
      left: -82px !important;
      top: -1px !important;
    }
    .ant-popover-title {
      border: none;
    }
    .ant-popover-inner-content {
      padding: 0;
      height: calc(100% - 74px);
      & > .ant-row {
        height: 100%;
      }
    }
    .ant-list-item-meta-title {
      margin: 0;
    }
    .ant-list-split .ant-list-item {
      border-bottom: 1px #e0e0e0 dashed;
    }
    ::-webkit-scrollbar {
      width: 6px;
    }
    ::-webkit-scrollbar-track {
      background: #f1f1f1;
    }
    ::-webkit-scrollbar-thumb {
      background: #888;
      border-radius: 20px;
      height: 250px !important;
    }
    .ant-popover-arrow {
      right: 83px;
    }
  }
  .headerNoti {
    padding: 20px;
  }
  .contentNoti {
    height: calc(100% - 60px);
  }
  .notiTitle {
    font-weight: 500;
    font-size: 34px;
    line-height: 38px;
    color: #223354;
  }
  .contentTitle {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    color: #223354;
  }
  .viewAll {
    color: #2c8ce4;
    font-size: 16px;
    &:hover {
      cursor: pointer;
      filter: brightness(.9);
    }
  }
  .systemContainer {
    padding: 10px 20px;
    background: hsl(240, 4%, 95%);
    max-height: 100%;
    :global {
      .ant-list-split .ant-list-item {
        border: none;
      }
    }
    .optionContainer {
      width: 100%;
      display: block !important;
      .groupOption {
        padding: 12px 10px;
        transition: 0.5s linear;
        &:hover {
          cursor: pointer;
          background-color: #c2dbf6;
          border-radius: 10px;
        }
      }
      .optionAmount {
        display: flex;
        justify-content: center;
        align-items: center;
        p {
          background: #d83c2c;
          margin-left: auto;
          margin-right: 10px;
          height: 22px;
          width: 22px;
          text-align: center;
          padding-top: 3px;
          border-radius: 50%;
          font-size: 10px;
          margin-top: 11px;
          color: #fff;
        }
      }
    }
  }
  .contentContainer {
    padding: 10px 6px 0px 20px;
    max-height: 100%;
    overflow: auto;
    background: $white-color;
    :global {
      .ant-spin {
        position: absolute;
        top: calc(50% - 16px);
        left: calc(50% - 19px);
      }
    }
  }
}

.scrollContainer {
  max-height: 708px;
  overflow: auto;
}

.active {
  cursor: pointer;
  background-color: #c2dbf6;
  border-radius: 10px;
}

@media screen and (max-height: 768px) {
  :global {
    .MuiTooltip-popper {
      height: calc(100vh - 100px) !important;
    }
  }
}

.notificationIcon {
  position: relative;
  cursor: pointer;
  span {
    position: absolute;
    background-color: #e74c3c;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    font-size: 9px;
    line-height: 19px;
    top: -6px;
    right: -9px;
    border: 1px solid #000;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.noData {    
  color: #e74c3c;
  text-align: center;
  font-size: 16px;
}