@import "variable";

.d-flex {
  display: flex;
}

.d-flex-ali-center {
  display: flex;
  align-items: center;
}

.d-flex-ali-start {
  display: flex;
  align-items: flex-start;
}

.d-flex-space-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.d-flex-ali-center-form {
  display: flex;
  align-items: center;
  .radioCus {
    flex: 1;
  }
}

.f-1 {
  flex: 1;
}

.t-align-right {
  text-align: right;
}

.ml-10 {
  margin-left: 10px;
}

.ml-20 {
  margin-left: 20px;
}

.mr-3 {
  margin-right: 3px;
}

.mr-5 {
  margin-right: 5px;
}

.mr-6 {
  margin-right: 6px;
}

.mr-10 {
  margin-right: 10px;
}

.mr-15 {
  margin-right: 15px;
}

.mr-20 {
  margin-right: 20px;
}

.mb-10 {
  margin-bottom: 10px !important;
}

.mb-14 {
  margin-bottom: 14px
}

.mb-20 {
  margin-bottom: 20px !important;
}

.mb-30 {
  margin-bottom: 30px !important;
}

.mb-0 {
  margin-bottom: 0px !important;
}

.mt-20 {
  margin-top: 20px;
}

.mt-10 {
  margin-top: 10px;
}

.mt-5 {
  margin-top: 5px;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-8 {
  margin-top: 8px !important;
}

.mt-12-5 {
  margin-top: 12.5px !important;
}

.pr-20 {
  padding-right: 20px;
}

.pr-15 {
  padding-right: 15px;
}

.w-50per {
  width: 50%;
}

.w-416 {
  width: 416px !important;
}

.w-860 {
  width: 860px !important;
}

.w-345 {
  width: 345px !important;
}

.mw-100 {
  max-width: 100px !important;
}

.mw-200 {
  max-width: 200px !important;
}

.mw-300 {
  max-width: 300px !important;
}

.h-45 {
  height: 45px !important;
}

.text-ali-left {
  text-align: left !important;
}

.mw-20 {
  max-width: 20px;
}

.bd-b-1 {
  border-bottom: 1px solid #d6d6d6;
}

.bd-r-15 {
  border-radius: 15px;
}

.ant-modal-content {
  border-radius: 5px;
}

.ant-modal-close {
  color: $main-color;
}

.ant-modal-close:hover,
.ant-modal-close:focus {
  color: $main-color-hover;
}

.ant-modal-close-x {
  width: 48px;
  height: 48px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 20px;
  svg {
    width: 24px;
    height: 24px;
  }
}

.ant-modal-header {
  background: $neutral-header-color;
  padding: 9px 20px;
  border-bottom: none;
  border-radius: 5px 5px 0px 0px;
}

.ant-modal-title {
  color: $white-color;
  font-size: 20px;
  font-weight: 700;
  line-height: 30px;
}

.ant-modal-body {
  padding: 30px 20px;
}

.modal--confirm {
  top: calc(50% - 183px);
  .ant-modal-content {
    border-radius: 15px;
  }
}

.modal--add-permission {
  top: calc(50% - 208px);
}

.modal--add-gift {
  top: calc(50% - 296px);
}

.modal--add-discount {
  top: calc(50% - 252px);
}

.modal--detail-coin {
  top: calc(50% - 220px);
}

.modal--add-coin {
  top: calc(50% - 179px);
}

.modal--destroy-coin {
  top: calc(50% - 205px);
}

.modal--transfer-coin {
  top: 20px;
}

.modal--transaction-history {
  top: 60px;
}

.modal--wallet {
  top: calc(50% - 193px);
}

.modal--withdraw {
  top: calc(50% - 261px);
}

.modal--deposit {
  top: calc(50% - 156px);
}

.modal--transfer-coin-employee-wallet {
  top: 60px;
}

.textAreaCus {
  height: 110px !important;
  padding: 12px 10px;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px !important;
  border: 1px solid #d6d6d6;
  border-radius: 5px;
  color: #3b3b3b;
  &::placeholder {
    color: #c4c4c4;
  }
  &:hover {
    border-color: $main-color;
  }
  &:focus {
    border-color: $main-color !important;
    box-shadow: 0px 0px 0px 2px rgba(254, 166, 40, 0.2);
  }
}

.ant-input-number {
  width: 100%;
  height: 45px;
  border: 1px solid #d6d6d6;
  border-radius: 5px;
}

.ant-input-number-handler-wrap {
  border-radius: 5px;
}

.ant-input-number-input-wrap {
  height: 100%;
}

.inputNumberCus {
  height: 100%;
  input {
    height: 100%;
    padding: 0 10px;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #3b3b3b;
    &::placeholder {
      color: #c4c4c4;
    }
  }
}

.inputPreCus {
  background: $white-color;
  border: 1px solid #d6d6d6;
  border-radius: 5px;
  height: 100%;
  input {
    height: 100%;
    padding: 0 10px;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    color: #3b3b3b;
    &::placeholder {
      color: #c4c4c4;
    }
  }
  .ant-input-prefix {
    margin-right: 12.5px;
  }
}

.inputCustom {
  background-color: $white-color;
  border: 1px solid #d6d6d6;
  border-radius: 5px;
  display: flex;
  align-items: center;
  height: 36px;
  font-size: 14px;
  padding: 7px 9px;
  input {
    color: #3b3b3b;
    font-weight: 400;
    font-size: inherit;
    line-height: 20px;
    &::placeholder {
      color: #c4c4c4;
    }
  }
  .ant-input-prefix {
    margin-right: 10px;
  }
  &:hover {
    border-color: $main-color !important;
  }
  .ant-input-number-input-wrap {
    width: 100%;
    display: flex;
    align-items: center;
    input {
      padding: 0;
    }
  }
  &:focus {
    border-color: $main-color !important;
    box-shadow: 0px 0px 0px 2px rgba(254, 166, 40, 0.2);
  }
  .ant-picker-focused {
    border-color: $main-color !important;
    box-shadow: 0px 0px 0px 2px rgba(254, 166, 40, 0.2) !important;
  }
  .ant-picker-suffix {
    margin-left: 0;
  }
}

.input--warning {
  border-color: red !important;
  box-shadow: 0px 0px 0px 2px rgba(255, 0, 0, 0.2) !important;
  &:hover {
    border-color: red !important;
  }
}

.ant-input-number-disabled {
  background-color: #f5f5f5 !important;
  border-color: #d9d9d9 !important;
}

.inputForm,
.dateForm {
  height: 45px;
}

//custom color focus input
.ant-input-affix-wrapper-focused,
.ant-input-number-focused,
.ant-picker-focused {
  box-shadow: 0px 0px 0px 2px rgba(254, 166, 40, 0.2);
}

.inputDateCus {
  border: 1px solid #d6d6d6;
  border-radius: 5px;
  height: 100%;
  input {
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    color: #3b3b3b;
    &::placeholder {
      color: #c4c4c4;
    }
  }
}

.andSearch {
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  color: #333333;
  margin: 0 5px;
  min-width: 10px;
  display: inline-block;
}

.btn {
  background-color: $main-color;
  border-radius: 10px;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  color: $white-color;
  border: none;
  height: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 150px;
  img {
    margin-right: 5px;
  }
}

.btn--reset {
  background: #223354;
  height: 40px;
  width: 100%;
}

.btn--cancel,
.btn--confirm {
  border-radius: 15px;
}

.btn--cancel {
  background: linear-gradient(90deg, #cccccc 0%, #e7e7e7 100%);
  color: #888888;
}

.btn--cancel:hover,
.btn--cancel:focus {
  background: linear-gradient(90deg, #cccccc 50%, #e7e7e7 100%) !important;
  color: #888888 !important;
}

.btn--confirm {
  background: linear-gradient(90deg, #e08d15 0%, #fea628 100%);
}

.btn--confirm:hover,
.btn--confirm:focus {
  background: linear-gradient(90deg, #e08d15 50%, #fea628 100%) !important;
}

.btn--reset:hover,
.btn--reset:focus {
  background: #152035 !important;
  color: $white-color;
  border: none;
}

.btnForm {
  width: 100%;
}

.btn:hover,
.btn:focus {
  background: $main-color-hover;
  color: $white-color;
  border: none;
}

.btn--submit {
  width: 100%;
  height: 36px;
}

.formItemCusHeight {
  .ant-form-item-control-input-content {
    height: 45px;
  }
}

.radioCus {
  display: flex;
  align-items: center;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #3b3b3b;
  height: 20px;
  align-items: center;
  margin-right: 0;
  .ant-radio {
    top: 0;
  }
  .ant-radio-inner::after {
    width: 20px;
    height: 20px;
    margin-top: -10px;
    margin-left: -10px;
    background-color: $main-color;
  }
  .ant-radio-inner {
    width: 20px;
    height: 20px;
    border: 1.5px solid #3b3b3b;
  }
  .ant-radio-checked .ant-radio-inner,
  .ant-radio:hover .ant-radio-inner {
    border-color: $main-color;
  }
  input {
    width: 20px;
    height: 20px;
  }
}

.radioForm {
  flex-direction: row;
  .ant-radio-wrapper {
    flex: 1;
  }
}

.tableCus {
  width: 100%;
  ::-webkit-scrollbar {
    height: 10px;
    width: 10px;
  }

  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  ::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 20px;
  }
  .ant-table {
    border-radius: 5px;
  }
  .ant-table.ant-table-bordered > .ant-table-container {
    border-left: 1px solid #d6d6d6;
  }
  .ant-table-container {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }
  table {
    border-top: 1px solid #d6d6d6 !important;
    border-radius: 5px 5px 0 0 !important;
  }
  .ant-table.ant-table-bordered
    > .ant-table-container
    > .ant-table-content
    > table
    > thead
    > tr
    > th {
    border-right: 1px solid #d6d6d6;
  }
  .ant-table-container table > thead > tr:first-child th:first-child {
    border-top-left-radius: 5px;
  }
  .ant-table-thead > tr > th {
    border-bottom: 1px solid #d6d6d6;
    background: #e9ebee;
    padding: 7.5px 7.5px;
  }
  .ant-table.ant-table-bordered
    > .ant-table-container
    > .ant-table-content
    > table
    > tbody
    > tr
    > td {
    border-right: 1px solid #d6d6d6;
  }
  .ant-table-tbody > tr > td {
    border-right: 1px solid #d6d6d6;
    border-bottom: 1px solid #d6d6d6;
    padding: 12.5px 12.5px;
  }
  tbody > tr:nth-child(2n) {
    background: #f8f8f8;
    .ant-table-cell-fix-right {
      background: #f8f8f8;
    }
  }
  .ant-table-container table > thead > tr:first-child th:last-child {
    border-top-right-radius: 5px;
  }
  .ant-table-thead .ant-checkbox-inner {
    border: 1.5px solid #3b3b3b;
    background: #e9ebee;
  }
  .ant-table-tbody .ant-checkbox-inner {
    border: 1.5px solid #3b3b3b;
    background: #e9ebee;
  }
  .ant-checkbox-inner {
    border-radius: 4px;
    width: 20px;
    height: 20px;
  }
  .ant-checkbox:hover .ant-checkbox-inner {
    border-color: $main-color;
  }
  .ant-checkbox-checked .ant-checkbox-inner {
    background: $main-color;
    color: $white-color;
    border: 1.5px solid $main-color;
  }
  .ant-checkbox-inner::after {
    left: 24.5%;
  }
  .ant-checkbox-checked::after {
    border: none;
  }
  .ant-checkbox-indeterminate .ant-checkbox-inner::after {
    width: 0;
    height: 0;
    background: none;
  }
  .ant-table-tbody > tr.ant-table-row-selected > td {
    background: rgba(254, 166, 40, 0.4);
  }
  .ant-table-cell-row-hover {
    background: rgb(238, 220, 195) !important;
  }
  .ant-table-cell-fix-right {
    background: #fff;
  }
}

.table--rowSelect {
  tbody > tr {
    cursor: pointer;
  }
}

.table-scroll {
  .ant-table-body {
    max-height: 55vh !important;
  }
}

.table-advance-coin-scroll {
  .ant-table-body {
    max-height: 40vh !important;
  }
}

.paginationCus {
  padding: 12px 10px;
  display: flex;
  border-bottom: 1px solid #d6d6d6;
  border-right: 1px solid #d6d6d6;
  border-left: 1px solid #d6d6d6;
  border-radius: 0 0 5px 5px;
  height: 44px;
  align-items: center;
  position: relative;
  .ant-pagination-total-text {
    width: 100%;
    margin-right: 0;
    display: flex;
  }
  .ant-pagination-item {
    display: none;
  }
  .ant-pagination-options {
    position: absolute;
    right: 123px;
    .ant-select-selector {
      border: none;
    }
  }
  .ant-pagination-prev button,
  .ant-pagination-next button {
    border: none;
  }
  .ant-pagination-prev {
    position: absolute;
    right: 78px;
  }
  .ant-pagination-next {
    position: absolute;
    right: 16px;
  }
  .ant-select-focused.ant-select .ant-select-selector {
    border: none !important;
    box-shadow: none !important;
  }
  .ant-select-selection-item {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #888888;
  }
  .ant-pagination-jump-next-custom-icon {
    display: none;
  }
}

.d-none {
  display: none !important;
}

.uploadCustom {
  text-align: center;
  .ant-upload-list {
    text-align: center;
  }
  .ant-upload.ant-upload-select-picture-card {
    width: 150px;
    height: 150px;
    border: 1px dashed #888888;
    margin: 0;
    border-radius: 0;
  }
}

.btnNone {
  border: none;
  background: none;
  outline: none;
  cursor: pointer;
  button {
    border: none !important;
    background: none !important;
    outline: none !important;
  }
}

.selectCustom {
  width: 100%;
  .ant-select-selector {
    background-color: $white-color !important;
    border: 1px solid #d6d6d6 !important;
    border-radius: 5px !important;
    min-height: 36px;
    display: flex;
    align-items: center;
  }
  input {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    &::placeholder {
      color: #C4C4C4;
    }
    height: 100% !important;
  }
  &:hover {
    .ant-select-selector {
      border: 1px solid $main-color !important;
    }
  }
  .ant-select-selection-item {
    height: 100%;
  }
}

.dropdownOverlay {
  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    background: rgba(254, 166, 40, 0.4) !important;
  }
  .ant-select-item-option-state {
    color: $main-color !important;
  }
}

.selectForm {
  .ant-select-selector {
    min-height: 45px !important;
  }
  input {
    min-height: 45px !important;
  }
}

.ant-select-focused {
  .ant-select-selector {
    box-shadow: 0px 0px 0px 2px rgba(254, 166, 40, 0.2) !important;
  }
}

.ant-select-selection-item {
  display: flex;
  align-items: center;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  .avatar {
    display: none;
  }
  .info {
    margin-left: 0;
  }
  .info p {
    display: none;
  }
  .info h3 {
    height: 100%;
    display: flex;
    align-items: center;
  }
}
.ant-checkbox-inner {
  border-color: #000000;
  border-radius: 5px;
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-checked .ant-checkbox-inner {
  border-color: #e08d15;
}

.ant-checkbox-checked .ant-checkbox-inner {
  border-color: #e08d15;
  background-color: #e08d15;
}

.ant-checkbox-checked::after {
  border: none;
}

.amount {
  font-weight: bold;
}

.full-width {
  width: 100%;
}

.cascaderCustom {
  background: $white-color;
  border-radius: 5px;
  height: 100%;
}

.modal--confirm-logout {
  top: calc(50% - 90px) !important;
}

.ant-popconfirm {
  width: inherit;
  height: inherit;
  padding-top: inherit;
}

.avatar-40 {
  .ant-avatar {
    width: 40px;
    height: 40px;
  }
}

.avatar-61 {
  .ant-avatar {
    width: 61px;
    height: 61px;
  }
}

.avatar-30 {
  .ant-avatar {
    width: 30px;
    height: 30px;
  }
}

.disable-row {
  .ant-table-selection-column {
    pointer-events: none !important;
    cursor: not-allowed !important;
  }
  .ant-checkbox-inner {
    background: #d6d6d6 !important;
    border: 1.5px solid #d6d6d6 !important;
  }
}

.mg-0 {
  margin: 0;
}

.fs-12 {
  font-size: 12px;
}

.popconfirmCustom {
  max-width: 300px !important;
  .ant-popover-buttons {
    .ant-btn-default {
      &:hover {
        color: $main-color;
        border-color: $main-color;
      }
    }
    .ant-btn-primary {
      border-color: $main-color;
      background: $main-color;
      &:hover {
        background: $main-color-hover;
      }
    }
  }
  &__hideFooter {
    .ant-popover-buttons {
      display: none;
    }
  }
}

.cascaderCustom {
  width: 100%;
  .ant-select-selector {
    background-color: $white-color !important;
    border: 1px solid #d6d6d6 !important;
    border-radius: 5px !important;
    min-height: 36px !important;
    display: flex;
    align-items: center;
  }
  input {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    &::placeholder {
      color: #C4C4C4;
    }
  }
  &:hover {
    .ant-select-selector {
      border: 1px solid $main-color !important;
    }
  }
  .ant-select-selection-item {
    height: 100%;
  }
}

.cascaderOverlay {
  .ant-cascader-checkbox:hover .ant-cascader-checkbox-inner {
    border-color: $main-color;
  }
  .ant-cascader-checkbox-checked .ant-cascader-checkbox-inner {
    background: $main-color;
    color: $white-color;
    border: 1.5px solid $main-color;
  }
  .ant-cascader-menu-item-active:not(.ant-cascader-menu-item-disabled) {
    background: rgba(254, 166, 40, 0.4);
  }
  .ant-cascader-menu {
    height: auto;
  }
}

.tablePaginationCustom {
  border-bottom: 1px solid #d6d6d6;
  border-right: 1px solid #d6d6d6;
  border-left: 1px solid #d6d6d6;
  border-radius: 0 0 5px 5px;
  height: 44px;
  overflow: hidden !important;
  .MuiToolbar-root {
    min-height: 44px !important;
    align-items: center;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    padding-left: 10px;
    padding-right: 0;
    .MuiTablePagination-spacer {
      flex: none;
    }
    .MuiTablePagination-displayedRows {
      order: 1;
      margin-bottom: 0;
      flex: 1;
      color: #888888;
    }
    .MuiTablePagination-selectLabel {
      order: 2;
      margin-bottom: 0;
      color: #888888;
    }
    .MuiInputBase-root {
      order: 3;
      color: #888888;
    }
    .MuiTablePagination-actions {
      order: 4;
    }
  }
}

.ant-spin-dot-item {
  background-color: $main-color;
}

.ant-input-number-disabled {
  input {
    color: inherit;
  }
  &:hover {
    border-color: #d6d6d6 !important;
  }
}

.ant-input-disabled {
  &:hover {
    border-color: #d6d6d6 !important;
  }
}

.popover-text {
  .ant-popover-inner-content {
    max-width: 300px;
  }
}