.tab-custom {
  .ant-tabs-nav-wrap {
    width: 400px;
  }
  & > .ant-tabs-nav:before {
    border-bottom: none;
  }
  .ant-tabs-tab {
    flex: 1;
    padding: 8px 0px;
  }
  .ant-tabs-nav-list {
    width: 400px;
  }
  .ant-tabs-nav-operations {
    display: none !important;
  }
  .ant-tabs-tab+.ant-tabs-tab {
    margin: 0;
  }
  .ant-tabs-tab {
    justify-content: center;
  }
  .ant-tabs-tab-btn {
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #888888;
  }
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #3B3B3B;
  }
  .ant-tabs-ink-bar {
    height: 3px !important;
    background: #FEA628;
  }
}