.switchAppContainer {
  width: 541px;
  height: 722.3px;
  padding-top: 17.3px;
  left: auto !important;
  right: 30px !important;
  :global {
    .ant-popover-inner-content {
      padding: 20px 10px;
    }
    .ant-popover-content {
      border-radius: 5px;
      height: 100%;
    }
    .ant-popover-title {
      margin: 0 10px;
      padding: 0;
      padding-top: 27px;
    }
    .ant-popover-inner {
      height: 100%;
    }
    .ant-popover-arrow {
      right: 43px;
    }
  }
}

@media screen and (max-height: 768px) {
  .switchAppContainer {
    height: calc(100vh - 60px);
  }
}

.infoSwitchApp {
  h3 {
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    color: #223354;
    margin-bottom: 0;
  }
  p {
    font-size: 12px;
    line-height: 20px;
    color: #8d8d8d;
    margin-bottom: 0;
  }
  span {
    font-size: 12px;
    line-height: 20px;
    color: #8d8d8d;
  }
}

.appSwitchContainer {
  display: flex;
  flex-wrap: wrap;
}

.appSwitch {
  border-radius: 20px;
  min-width: 25%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 8px 0px;
  h3 {
    margin: 5px 0;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: #223354;
  }
  p {
    margin-bottom: 0;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    color: #8D8D8D;
  }
}

.appSwitch:hover {
  background: #e5e2e6;
  cursor: pointer;
  transition: 0.5s linear;
}

.imageSystem {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}