@import '~styles/variable';

.headerWrapper {
  display: flex;
  align-items: center;
  width: 100%;
  height: 60px;
  background: #222433;
  padding: 0 30px;
  position: fixed;
  top: 0;
  z-index: 999;
}

.menuWrapper {
  flex: 1;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.appName {
  font-weight: 700;
  font-size: 25px;
  line-height: 32px;
  color: $white-color;
  margin-left: 12px;
}

.menuWrapper {
  background: inherit;
  border-bottom: none;
  color: $white-color;
  margin-left: 50px;
  height: 100%;
  :global {
    .ant-menu-item::after {
      left: 0;
      right: 0;
    }
    .ant-menu-item-selected::after, .ant-menu-item:hover::after {
      border-bottom: 3px solid $main-color !important;
    }
    .ant-menu-item {
      padding: 0 10px !important;
      margin-right: 10px;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .ant-menu-item:hover, .ant-menu-item-selected {
      color: $white-color !important;
      background: rgba(255, 255, 255, 0.1);
    }
    .ant-menu-title-content {
      a {
        color: $white-color;
        font-weight: 700;
        font-size: 14px;
        line-height: 20px;
        &:hover {
          color: $white-color;
        }
      }
    }
  }
}

.userInfo {
  display: flex;
  align-items: center;
  position: relative;
  .avatar, .switchApp, .logout {
    cursor: pointer;
  }
  .avatar, .notification, .switchApp {
    padding-right: 20px;
  }
}

.amelaLogoContainer {
  display: flex;
  cursor: pointer;
}