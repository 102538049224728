@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import "./variable";

body {
  font-family: 'Roboto', sans-serif;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.label-bold {
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
}

.ellipsisOneLine {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.labelModal {
  display: flex;
  align-items: center;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #223354;
  span {
    flex: 5;
  }
}

.labelModal--coin {
  justify-content: space-between;
  span {
    flex: initial;
  }
}

.labelModal--left {
  span {
    flex: 2.5 !important;
  }
  .label-bold {
    flex: 7.5 !important;
  }
}

.coinLabel {
  background: rgba(254, 166, 40, 0.3);
  border-radius: 5px;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #223354;
  height: 40px;
}

.avatar {
  img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }
}

.info {
  height: 100%;
  margin-left: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  h3 {
    font-weight: 700;
    font-size: 14px;
    margin-bottom: 0;
  }
  p {
    font-size: 12px;
    color: #b8b7b7;
    margin-bottom: 0;
  }
}

.info--table {
  h3 {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #3B3B3B;
  }
}

.infoContainer {
  display: flex;
  align-items: center;
}

.clickText {
  color: #0085FF !important;
  text-decoration-line: underline;
  cursor: pointer;
  &--black {
    color: #000 !important;
  }
}

//----------------------
.input--large {
  height: 45px !important;
  font-size: 14px !important;
}
//----------------------
.select--large {
  min-height: 45px !important;
  .ant-select-selector {
    min-height: 45px !important;
  }
  input {
    height: 100% !important;
  }
}
//----------------------
.labelInForm {
  .amount {
    font-size: 16px;
  }
  padding: 0;
  background: #fff;
}
//---------------------
.coin-input--large {
  height: 45px !important;
}
//--------------------
.page-container {
  &--no-btn {
    margin-top: 45px;
  }
  &--no-sub-header {
    padding: 15px 30px;
  }
  .header {
    display: flex;
    justify-content: space-between;
    .back {
      display: flex;
      align-items: center;
      img {
        cursor: pointer;
      }
      h3 {
        margin-bottom: 0;
        font-weight: 700;
        font-size: 18px;
        line-height: 28px;
        color: #3B3B3B;
        margin-left: 13px;
      }
    }
    .confirm {
      height: 44px;
    }
  }
  .page-sub-header {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    .sub-header-btn {
      text-align: right;
      height: 44px;
      display: flex;
      justify-content: flex-end;
    }
  }
  .page-content {
    padding-top: 20px;
    display: flex;
    align-items: flex-start;
    .table-container {
      flex: 7.5;
      filter: drop-shadow(0px 5px 25px rgba(34, 51, 84, 0.15));
      background: $white-color;
      border-radius: 5px;
    }
    .filter-container {
      margin-left: 30px;
      flex: 2.5;
      max-width: 304px;
      background: $white-color;
      border: 1px solid #D6D6D6;
      box-shadow: 0px 5px 25px rgba(34, 51, 84, 0.15);
      border-radius: 5px;
      padding: 19px;
      &--scroll {
        width: calc(0.25 * (100vw - 90px));
      }
    }
    .info-container {
      &--scroll {
        width: calc(0.75 * (100vw - 90px));
      }
      flex: 7.5;
      .infoCU, .table {
        filter: drop-shadow(0px 5px 25px rgba(34, 51, 84, 0.15));
        background: $white-color;
        border-radius: 5px;
      }
      .infoCU {
        padding: 20px;
      }
    }
  }
}
.sub-wrapper {
  padding: 15px 30px;
  position: relative;
}
.sub-menu {
  position: absolute;
  display: flex;
  align-items: center;
}
//-----------------------------
.bg--label {
  background: rgba(254, 166, 40, 0.3);
  border-radius: 5px;
  padding: 5px;
}
//----------------------------
.img-h-30 {
  height: 30px;
  width: auto;
}
//----------------------------
.sort-icon {
  img {
    cursor: pointer;
  }
}
.text-multi-line-break {
  word-break: break-all;
}
.rs-none {
  resize: none;
}
.img-icon-wallet {
  width: 30px;
  height: 30px;
  object-fit: cover;
  border-radius: 50%;
}
.icon {
  &__click {
    cursor: pointer;
    pointer-events: auto;
  }
}

.disable-element {
  pointer-events: none;
}

.cursor-not-allowed {
  cursor: not-allowed;
}

.text-ali-right {
  text-align: right;
}